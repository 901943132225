import './App.css';
import ReactAudioPlayer from 'react-audio-player';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <h2>
                    Cariello Consulting Site - Under Construction
                </h2>
                <h4>Play Pomp and Circumstance</h4>
                <p>This will play infinitely</p>
                <ReactAudioPlayer
                    src={process.env.PUBLIC_URL + '/pomp_loop.mp3'}
                    loop={true}
                    controls
                />
            </header>
        </div>
    );
}

export default App;